@font-face {
font-family: '__titleFont_1a0559';
src: url(/_next/static/media/6e8507363c32c2ca-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__titleFont_Fallback_1a0559';src: local("Arial");ascent-override: 75.91%;descent-override: 18.91%;line-gap-override: 0.00%;size-adjust: 133.48%
}.__className_1a0559 {font-family: '__titleFont_1a0559', '__titleFont_Fallback_1a0559'
}

@font-face {
font-family: '__smallFont_d47d4f';
src: url(/_next/static/media/27cb1ddd7c6c5282-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__smallFont_Fallback_d47d4f';src: local("Arial");ascent-override: 83.50%;descent-override: 21.25%;line-gap-override: 0.00%;size-adjust: 118.82%
}.__className_d47d4f {font-family: '__smallFont_d47d4f', '__smallFont_Fallback_d47d4f'
}

@font-face {
font-family: '__smallBoldFont_14449a';
src: url(/_next/static/media/e59443d480bf1049-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__smallBoldFont_Fallback_14449a';src: local("Arial");ascent-override: 80.06%;descent-override: 20.31%;line-gap-override: 0.00%;size-adjust: 124.29%
}.__className_14449a {font-family: '__smallBoldFont_14449a', '__smallBoldFont_Fallback_14449a'
}

